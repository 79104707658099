<template>
  <v-app class="page_background">
    <div class="pl-5">
      <img src="../assets/Logo-AMALITECH.svg" alt="" />
    </div>
    <v-container class="fill-height d-flex flex-column justify-center align-center">
      <img src="../assets/Frame.svg" alt="" />
      <h1 class="text-center text_color mt-5">Restricted Access</h1>
      <p class="text-center text_color subtitle-1">
        You do not have permission to access this resource. Please contact
        <br />
        the administrator if you believe this is an error
      </p>
      <button
        class="back_btn"
        @click.prevent="backToDashboard()"
        small
      >
        Go Back Home
      </button>
    </v-container>
  </v-app>
</template>

<script>
import config from "../../public/config";
export default {
  methods: {
    backToDashboard() {
      return window.location.replace(config.loginUrl);
    },
  },
};

</script>

<style scoped>
.page_background {
  background-image: url("../assets/Pattern.svg");
  background-position-y: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.text_color {
  color: #474d66 !important;
  font-weight: 400;
}
.back_btn {
  width: 200px;
  height: 40px;
  padding: 8px 24px;
  border-radius: 8px;
  gap: 10px;
  background: #DD5928;
}
</style>
